import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AmbikaChefAPI } from './config';

const PandingPacking = ({ refreshOrders }) => {
    const [foodReadyList, setFoodReadyList] = useState([]);
    const [showPackingRedy, setShowPackingRedy] = useState(false)
    const [packingRedy, setPackingRedy] = useState()
    const [foodDetails, setFoodDetails] = useState()
    const [productQuts, setProductQuts] = useState()
    const [orderRemark, setOrderRemark] = useState()
    const [reqFor, setReqFor] = useState(false)
    const ambikaChefUserToken = localStorage.getItem('ambikaChefUserToken');
    const ambikaChefUserId = localStorage.getItem('ambikaChefUserId');

    const pandingPakingList = () => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/pendingPackingProductList`,
                { UserId: ambikaChefUserId },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setFoodReadyList(res?.data?.data);
            })
            .catch((err) => {
                // Handle error
            });
    }
    useEffect(() => {
        pandingPakingList()
    }, [ambikaChefUserToken, ambikaChefUserId]);

    const handelProductionRedy = (orderId, product) => {
        setFoodDetails(product)
        setProductQuts(product?.product_qty)
        setPackingRedy((prevDetails) => ({
            ...prevDetails,
            orderId: orderId,
            orderProductId: product?.orderProduct_id
            ,
        }));
        setShowPackingRedy(true)
    };


    const showAllProduct = () => {
        return foodReadyList?.map((order, index) => (
            <div key={index} className="text-start mt-4">
                <div className="d-flex align-items-center justify-content-between" style={{ fontSize: '14px' }}>
                    <p class='fw-semibold'>{order.pickup_date} | {order.pickup_time}</p>
                    <p className="fw-semibold"># {order.order_id}</p>
                </div>
                <div class='boxSadow1 rounded-3'>
                    {order.product_detail.map((cat, catIndex) => (
                        <div class='p-3 border-bottom border-2 border-dark-subtle'>
                            <div class='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p className="fw-semibold ">{cat?.productName} <span className=' text-secondary' style={{ fontSize: '12px' }}>{cat?.size_name}</span></p>
                                </div>
                                <div>
                                    <p className="fw-semibold "> <span>{cat?.product_qty}</span> </p>
                                </div>
                            </div>
                            <div className=' border-top'>
                                <div className="d-flex gap-2 align-items-center mt-2">
                                    {cat?.is_urgent !== 0 && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Urgent
                                        </p>
                                    )}
                                    {cat?.is_parcel !== 0 && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Packing
                                        </p>
                                    )}
                                    {cat?.eggless_option !== '' && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Less Cream
                                        </p>
                                    )}
                                </div>
                                {(cat?.shap_option !== "" || cat?.message_option !== "") &&
                                    <div className="d-flex gap-2 align-items-center mt-2">
                                        {
                                            cat?.shap_option !== "" && (
                                                <p class='text-secondary'>{cat?.shap_option}</p>
                                            )
                                        }
                                        {
                                            (cat?.shap_option !== "" && cat?.message_option !== "") && (
                                                <p class='text-secondary'>|</p>
                                            )
                                        }
                                        {
                                            cat?.message_option !== "" && (
                                                <p class='text-secondary'>{cat?.message_option}</p>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                            <div class='d-flex align-items-center gap-3 my-2'>
                                <button class='btn btn-success rounded-5 form-control' style={{ fontSize: '14px' }} onClick={() => handelProductionRedy(order?.order_id, cat)}>Packing Ready</button>

                            </div>
                        </div>

                    ))}
                </div>
            </div>
        ));
    };

    const handelMinus = () => {
        setProductQuts((prevProductQuts) => Math.max(prevProductQuts - 1, 0)); // Ensure it doesn't go below 0
    };

    const handelPlush = () => {
        setProductQuts((prevProductQuts) => Math.min(prevProductQuts + 1, foodDetails?.product_qty)); // Ensure it doesn't go above max product_qty
    };




    const handelProductionPackingSend = () => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/packingReadyProduct`,
                { UserId: ambikaChefUserId, ...packingRedy, remarks: orderRemark, Qty: productQuts },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setShowPackingRedy(false)
                setOrderRemark('')
                Swal.fire({
                    text: `${res?.data?.message}`,
                });
                pandingPakingList()
                refreshOrders()
            })
            .catch((err) => {
                // Handle error
            });
    }



    return (
        <div>
            {
                showPackingRedy === true &&
                <div style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.6)', position: 'fixed', top: '0', left: '0', zIndex: '10' }} class='d-flex align-items-center justify-content-center'>
                    <div class='p-3 col-md-4 col-10 bg-light rounded-4'>
                        <p class='fs-5'>Select Quantity</p>
                        <hr />
                        <div class='d-flex align-items-center justify-content-between '>
                            <div>
                                <p>{foodDetails?.productName}</p>
                            </div>
                            <div class='d-flex gap-2 '>
                                <button class='btn btn-outline-danger p-0 px-2' disabled={productQuts <= 1 ? true : false} onClick={handelMinus}>-</button>
                                <div class='px-2 border rounded-3'>{productQuts}</div>
                                <button class='btn btn-outline-success p-0 px-2' disabled={productQuts >= foodDetails?.product_qty ? true : false} onClick={handelPlush}>+</button>

                            </div>

                        </div>
                        <input type="text" placeholder='Write Remarks' class='form-control mt-2' onChange={(e) => setOrderRemark(e.target.value)} />
                        <div class='d-flex gap-3'>

                            <button class='btn btn-success mt-3 form-control rounded-5' onClick={() => handelProductionPackingSend()}> Ready</button>

                            <button class='btn  btn-outline-danger mt-3 form-control rounded-5' onClick={() => setShowPackingRedy(false)}> Close</button>

                        </div>
                    </div>

                </div>
            }


            <div className="col-lg-6 col-md-8 col-11 m-auto mt-4">
                {
                    foodReadyList != '' ?
                        showAllProduct()
                        :
                        <div>
                            <br />
                            <p class='fs-1 mt-4'>No Product Found...</p>
                        </div>
                }
                <br />
            </div>
        </div>
    );
};

export default PandingPacking;
