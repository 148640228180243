import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AmbikaChefAPI } from './config';

const OrderProcess = ({ refreshOrders }) => {
    const [placeOrderList, setPlaceOrderList] = useState([]);
    const [openSection, setOpenSection] = useState(null); // To track the currently open section
    const [showSendProduction, setShowSendProduction] = useState(false)
    const [showFoodRedy, setShowFoodRedy] = useState(false)

    const [orderProduction, setOrderProduction] = useState()
    const [foodReady, setFoodReady] = useState()
    const [orderRemark, setOrderRemark] = useState()
    const [foodDetails, setFoodDetails] = useState()
    const [productQuts, setProductQuts] = useState()
    const [reqFor, setReqFor] = useState(false)


   
    const ambikaChefUserToken = localStorage.getItem('ambikaChefUserToken');
    const ambikaChefUserId = localStorage.getItem('ambikaChefUserId');


    const accepetOrderPlayList = () => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/acceptedOrderProductList`,
                { UserId: ambikaChefUserId },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setPlaceOrderList(res?.data?.data);
            })
            .catch((err) => {
                // Handle error
            });
    }

    useEffect(() => {
        accepetOrderPlayList()
    }, [ambikaChefUserToken, ambikaChefUserId]);

    const toggleSection = (index) => {
        setOpenSection(openSection === index ? null : index);
    };

    const handelSendProduction = (orderId, productId) => {
        setOrderProduction((prevDetails) => ({
            ...prevDetails,
            orderId: orderId,
            orderProductId: productId,
        }));
        setShowSendProduction(true)
    };

    const handelFoodReady = (orderId, product) => {
        console.log(orderId,product);
        setFoodDetails(product)
        setProductQuts(product?.product_qty)
        setFoodReady((prevDetails) => ({
            ...prevDetails,
            orderId: orderId,
            orderProductId: product?.orderProduct_id,
        }));
        setShowFoodRedy(true)
    };



    const showAllProduct = () => {
        return placeOrderList?.map((order, index) => (
            <div key={index} className="text-start mt-4">
                <div className="d-flex align-items-center justify-content-between" style={{ fontSize: '14px' }}>
                    <p class='fw-semibold'>{order.pickup_date} | {order.pickup_time}</p>
                    <p className="fw-semibold"># {order.order_id}</p>
                </div>
                <div class='boxSadow1 rounded-3'>

                    {order.product_detail.map((cat, catIndex) => (
                        <div class='p-3 border-bottom border-2 border-dark-subtle'>
                            <div class='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p className="fw-semibold ">{cat?.productName} <span className=' text-secondary' style={{ fontSize: '12px' }}>{cat?.size_name}</span></p>
                                </div>
                                <div>
                                    <p className="fw-semibold "> <span>{cat?.product_qty}</span> </p>
                                </div>
                            </div>
                            <div className=' border-top'>
                                <div className="d-flex gap-2 align-items-center mt-2">
                                    {cat?.is_urgent !== 0 && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Urgent
                                        </p>
                                    )}
                                    {cat?.is_parcel !== 0 && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Packing
                                        </p>
                                    )}
                                    {cat?.eggless_option !== '' && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Less Cream
                                        </p>
                                    )}
                                </div>
                                {(cat?.shap_option !== "" || cat?.message_option !== "") &&
                                    <div className="d-flex gap-2 align-items-center mt-2">
                                        {
                                            cat?.shap_option !== "" && (
                                                <p class='text-secondary'>{cat?.shap_option}</p>
                                            )
                                        }
                                        {
                                            (cat?.shap_option !== "" && cat?.message_option !== "") && (
                                                <p class='text-secondary'>|</p>
                                            )
                                        }
                                        {
                                            cat?.message_option !== "" && (
                                                <p class='text-secondary'>{cat?.message_option}</p>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                            <div class='d-flex align-items-center gap-3 my-2'>
                                {
                                   ( cat?.chef_id2 !== "" &&   cat?.chef_id2 !== null) &&
                                <button class='btn btn-success rounded-5 form-control' style={{ fontSize: '14px' }} onClick={() => handelSendProduction(order?.order_id, cat?.orderProduct_id)}>Send for Production</button>
                                }
                                <button class='btn btn-success rounded-5 form-control' style={{ fontSize: '14px' }} onClick={() => handelFoodReady(order?.order_id, cat)}>Food Ready</button>

                            </div>
                        </div>
                        // <div key={catIndex} className="border rounded-3 border-1 mt-2 bg-light">
                        //     <div
                        //         className="p-2 d-flex align-items-center justify-content-between"
                        //         data-bs-toggle="collapse"
                        //         href={`#orderCatList${index}-${catIndex}`}
                        //         role="button"
                        //         aria-expanded={openSection === `${index}-${catIndex}`}
                        //         aria-controls={`orderCatList${index}-${catIndex}`}
                        //         onClick={() => toggleSection(`${index}-${catIndex}`)}
                        //     >
                        //         <p className="fs-5 fw-semibold">{cat.category_name}</p>
                        //         <p>
                        //             <i className={`bi ${openSection === `${index}-${catIndex}` ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                        //         </p>
                        //     </div>
                        //     {/* <div className={`collapse ${openSection === `${index}-${catIndex}` ? 'show' : ''}`} id={`orderCatList${index}-${catIndex}`}>
                        //         {cat.product_detail.map((el, productIndex) => (
                        //             <div key={productIndex} className="border-top p-2">
                        //                 <div className="mx-2">
                        //                     <p className="fw-semibold fs-5">{el?.productName}</p>
                        //                     <div className="d-flex align-items-center justify-content-between mt-2">
                        //                         <p>Weight:</p>
                        //                         <p>{el?.size_name}</p>
                        //                     </div>
                        //                     <div className="d-flex align-items-center justify-content-between">
                        //                         <p>Shape:</p>
                        //                         <p>{el?.shap_option}</p>
                        //                     </div>
                        //                     <div className="d-flex align-items-center justify-content-between">
                        //                         <p>Less Cream:</p>
                        //                         <p>{el?.eggless_option}</p>
                        //                     </div>
                        //                     <div className="d-flex align-items-center justify-content-between">
                        //                         <p>Message on the Cake:</p>
                        //                         <p>{el?.message_option}</p>
                        //                     </div>
                        //                     <div className="d-flex gap-3 align-items-center">
                        //                         {el?.is_urgent !== 0 && (
                        //                             <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                        //                                 Urgent
                        //                             </p>
                        //                         )}
                        //                         {el?.is_parcel !== 0 && (
                        //                             <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                        //                                 Packing
                        //                             </p>
                        //                         )}
                        //                     </div>
                        //                     <div class='d-flex align-items-center gap-3 my-2'>
                        //                         <button class='btn btn-success rounded-5 form-control'style={{fontSize:'14px'}} onClick={()=>handelSendProduction( order?.order_id,el?.product_id )}>Send for Production</button>
                        //                         <button class='btn btn-success rounded-5 form-control'style={{fontSize:'14px'}} onClick={()=>handelFoodReady( order?.order_id,el?.product_id )}>Food Ready</button>

                        //                     </div>
                        //                 </div>
                        //             </div>
                        //         ))}
                        //     </div> */}
                        // </div>
                    ))}
                </div>



            </div>
        ));
    };
    const handelSendForProduction = () => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/sendForProductionProduct`,
                { UserId: ambikaChefUserId, ...orderProduction, remarks: orderRemark },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setShowSendProduction(false)
                setOrderRemark('')
                Swal.fire({
                    text: `${res?.data?.message}`,
                });
                accepetOrderPlayList()
                refreshOrders()
            })
            .catch((err) => {
                // Handle error
            });
    }


    const handelMinus = () => {
        setProductQuts((prevProductQuts) => Math.max(prevProductQuts - 1, 0)); // Ensure it doesn't go below 0
    };

    const handelPlush = () => {
        setProductQuts((prevProductQuts) => Math.min(prevProductQuts + 1, foodDetails?.product_qty)); // Ensure it doesn't go above max product_qty
    };

    useEffect(() => {
        if (foodDetails?.product_qty == productQuts) {
            setReqFor(true)
        } else {
            setReqFor(false)
        }
    }, [foodDetails, productQuts])


    const handelProductionRedySend = () => {
        axios
            .post(
                'https://www.erp.ambikadairyfarm.com/api/chef/foodReadyProduct',
                { UserId: ambikaChefUserId, ...foodReady, remarks: orderRemark, Qty: productQuts },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setShowFoodRedy(false)
                setOrderRemark('')
                Swal.fire({
                    text: `${res?.data?.message}`,
                });
                accepetOrderPlayList()
                refreshOrders()
            })
            .catch((err) => {
                // Handle error
            });
    }
    const handelProductionpartiallyRedySend = () => {
        axios
            .post(
                'https://www.erp.ambikadairyfarm.com/api/chef/foodPartiallyReadyProduct',
                { UserId: ambikaChefUserId, ...foodReady, remarks: orderRemark, Qty: productQuts },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setShowFoodRedy(false)
                setOrderRemark('')
                Swal.fire({
                    text: `${res?.data?.message}`,
                });
                accepetOrderPlayList()
                refreshOrders()
            })
            .catch((err) => {
                // Handle error
            });
    }

    // const handelFoodready = () => {
    //     axios
    //         .post(
    //             `${AmbikaChefAPI}/chef/productionReadyProduct`,
    //             { UserId: ambikaChefUserId, ...foodReady, remarks: orderRemark },
    //             { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
    //         )
    //         .then((res) => {
    //             setShowFoodRedy(false)
    //             setOrderRemark('')
    //             Swal.fire({
    //                 text: `${res?.data?.message}`,
    //             });
    //             accepetOrderPlayList() 
    //             refreshOrders()
    //         })
    //         .catch((err) => {
    //             // Handle error
    //         });
    // }

    return (
        <div>
            {
                showSendProduction === true &&
                <div style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.6)', position: 'fixed', top: '0', left: '0', zIndex: '10' }} class='d-flex align-items-center justify-content-center'>
                    <div class='p-3 col-md-4 col-10 bg-light rounded-4'>
                        <p class='fs-2'>Remarks for Production</p>
                        <input type="text" placeholder='Write Remarks' class='form-control mt-2' onChange={(e) => setOrderRemark(e.target.value)} />
                        <div class='d-flex gap-3'>
                            <button class='btn btn-success mt-3 form-control rounded-5' onClick={() => handelSendForProduction()}> Save</button>
                            <button class='btn  btn-outline-danger mt-3 form-control rounded-5' onClick={() => setShowSendProduction(false)}> Close</button>
                        </div>
                    </div>

                </div>
            }
             {
                showFoodRedy === true &&
                <div style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.6)', position: 'fixed', top: '0', left: '0', zIndex: '10' }} class='d-flex align-items-center justify-content-center'>
                    <div class='p-3 col-md-4 col-10 bg-light rounded-4'>
                        <p class='fs-5'>Select Quantity</p>
                        <hr />
                        <div class='d-flex align-items-center justify-content-between '>
                            <div>
                                <p>{foodDetails?.productName}</p>
                            </div>
                            <div class='d-flex gap-2 '>
                                <button class='btn btn-outline-danger p-0 px-2' disabled={productQuts <= 1 ? true : false} onClick={handelMinus}>-</button>
                                <div class='px-2 border rounded-3'>{productQuts}</div>
                                <button class='btn btn-outline-success p-0 px-2' disabled={productQuts >= foodDetails?.product_qty ? true : false} onClick={handelPlush}>+</button>

                            </div>

                        </div>
                        <input type="text" placeholder='Write Remarks' class='form-control mt-2' onChange={(e) => setOrderRemark(e.target.value)} />
                        <div class='d-flex gap-3'>
                            {
                                reqFor ?
                                    <button class='btn btn-success mt-3 form-control rounded-5' onClick={() => handelProductionRedySend()}> Ready</button>
                                    :
                                    <button class='btn btn-success mt-3 form-control rounded-5' onClick={() => handelProductionpartiallyRedySend()}>Partialy Ready</button>
                            }
                            <button class='btn  btn-outline-danger mt-3 form-control rounded-5' onClick={() => setShowFoodRedy(false)}> Close</button>

                        </div>
                    </div>

                </div>
            }

            {/* {
                showFoodRedy === true &&
                <div style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.6)', position: 'fixed', top: '0', left: '0', zIndex: '10' }} class='d-flex align-items-center justify-content-center'>
                    <div class='p-3 col-md-4 col-10 bg-light rounded-4'>
                        <p class='fs-2'>Remarks for Food ready</p>
                        <input type="text" placeholder='Write Remarks' class='form-control mt-2' onChange={(e) => setOrderRemark(e.target.value)} />
                        <div class='d-flex gap-3'>
                            <button class='btn btn-success mt-3 form-control rounded-5' onClick={() => handelFoodready()}> Save</button>
                            <button class='btn  btn-outline-danger mt-3 form-control rounded-5' onClick={() => setShowFoodRedy(false)}> Close</button>
                        </div>
                    </div>

                </div>
            } */}
            <div className="col-lg-6 col-md-8 col-11 m-auto mt-4">
                {
                    placeOrderList != '' ?
                        showAllProduct()
                        :
                        <div>
                            <br />
                            <p class='fs-1 mt-4'>No Product Found...</p>
                        </div>
                }
                <br />
            </div>
        </div>
    );
};

export default OrderProcess;
