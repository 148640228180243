import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AmbikaChefAPI } from './config'
import { useNavigate } from 'react-router-dom'

const OrdrNav = ({ orderList, refreshOrders }) => {

    const [selectOrderList, setSelectOrderList] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        if (orderList) {
            const firstNonZeroEntry = Object.entries(orderList).find(([key, value]) => value.totCount > 0);
            if (firstNonZeroEntry) {
                setSelectOrderList(firstNonZeroEntry[0]); 
            }
        }
    }, [orderList]); 

  

    const scrollRight = () => {
        const container = document.querySelector('.orderOption');
        container.scrollBy({ left: 200, behavior: 'smooth' });
    };

    const scrollLeft = () => {
        const container = document.querySelector('.orderOption');
        container.scrollBy({ left: -200, behavior: 'smooth' });
    };
    return (
        <div>
            <div className="col-lg-6 col-md-8 col-11 m-auto "  >
                <div className='d-flex align-items-center gap-2 mt-3 col-12 justify-content-between' >
                    <button class="btn btn-outline-success px-1 p-0 " onClick={scrollLeft}><i class="bi bi-arrow-left-short"></i></button>
                    <div class='  orderOption '>
                        {
                            orderList?.orderPlace?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "orderPlace" ? { color: "#1a8855", fontWeight: "bold", borderBottom: "2px solid #1a8855" } : { color: "black", fontWeight: "normal" }} onClick={() => navigate('order-place')}>
                                <p class='text-nowrap'>{orderList?.orderPlace?.name}</p>
                                <div class='orderNotification'>{orderList?.orderPlace?.totCount}</div>
                            </div>
                        }
                        {
                            orderList?.acceptedOrder?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "acceptedOrder" ? { color: "#1a8855", fontWeight: "bold", borderBottom: "2px solid #1a8855" } : { color: "black", fontWeight: "normal" }} onClick={() => navigate('accepted-order')}>
                                <p class='text-nowrap'>{orderList?.acceptedOrder?.name}</p>
                                <div class='orderNotification'>{orderList?.acceptedOrder?.totCount}</div>
                            </div>
                        }
                        {
                            orderList?.sendForProduction?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "sendForProduction" ? { color: "#1a8855", fontWeight: "bold", borderBottom: "2px solid #1a8855" } : { color: "black", fontWeight: "normal" }} onClick={() => navigate('send-for-production')}>
                                <p class='text-nowrap'>{orderList?.sendForProduction?.name}</p>
                                <div class='orderNotification'>{orderList?.sendForProduction?.totCount}</div>
                            </div>
                        }
                        {
                            orderList?.productionReady?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "productionReady" ? { color: "#1a8855", fontWeight: "bold", borderBottom: "2px solid #1a8855" } : { color: "black", fontWeight: "normal" }} onClick={() => navigate('production-ready')}>
                                <p class='text-nowrap'>{orderList?.productionReady?.name}</p>
                                <div class='orderNotification'>{orderList?.productionReady?.totCount}</div>
                            </div>
                        }
                        {
                            orderList?.pendingPacking?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "pendingPacking" ? { color: "#1a8855", fontWeight: "bold", borderBottom: "2px solid #1a8855" } : { color: "black", fontWeight: "normal" }} onClick={() => navigate('pending-packing')}>
                                <p class='text-nowrap'>{orderList?.pendingPacking?.name}</p>
                                <div class='orderNotification'>{orderList?.pendingPacking?.totCount}</div>
                            </div>
                        }
                        {
                            orderList?.foodReady_report?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "foodReady_report" ? { color: "#1a8855", fontWeight: "bold", borderBottom: "2px solid #1a8855" } : { color: "black", fontWeight: "normal" }} onClick={() => navigate('food-ready-report')}>
                                <p class='text-nowrap'>{orderList?.foodReady_report?.name}</p>
                                <div class='orderNotification'>{orderList?.foodReady_report?.totCount}</div>
                            </div>
                        }
                        {
                            orderList?.productionReady_report?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "productionReady_report" ? { color: "#1a8855", fontWeight: "bold", borderBottom: "2px solid #1a8855" } : { color: "black", fontWeight: "normal" }} onClick={() => navigate('production-ready-report')}>
                                <p class=' text-nowrap'>{orderList?.productionReady_report?.name}</p>
                                <div class='orderNotification'>{orderList?.productionReady_report?.totCount}</div>
                            </div>
                        }
                        {
                            orderList?.packingReady_report?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "packingReady_report" ? { color: "#1a8855", fontWeight: "bold", borderBottom: "2px solid #1a8855" } : { color: "black", fontWeight: "normal" }} onClick={() => navigate('packing-ready-report')}>
                                <p class='text-nowrap'>{orderList?.packingReady_report?.name}</p>
                                <div class='orderNotification'>{orderList?.packingReady_report?.totCount}</div>
                            </div>
                        }

                    </div>

                    <button class="btn btn-outline-success px-1 p-0" onClick={scrollRight}><i class="bi bi-arrow-right-short"></i></button>

                </div>

            </div>
        </div>
    )
}

export default OrdrNav