import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AmbikaChefAPI } from './config';

const ProductionProductList = ({refreshOrders}) => {
    const [placeOrderList, setPlaceOrderList] = useState([]);
    const [openSection, setOpenSection] = useState(null); // To track the currently open section
    const [showSendProduction, setShowSendProduction] = useState(false)
    const [showFoodRedy, setShowFoodRedy] = useState(false)

    const [orderProduction,setOrderProduction] = useState()
    const [foodReady, setFoodReady] = useState()
    const [orderRemark, setOrderRemark] = useState()
    const ambikaChefUserToken = localStorage.getItem('ambikaChefUserToken');
    const ambikaChefUserId = localStorage.getItem('ambikaChefUserId');

    useEffect(() => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/sendForProductionProductList`,
                { UserId: ambikaChefUserId },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setPlaceOrderList(res?.data?.data);
            })
            .catch((err) => {
                // Handle error
            });
    }, [ambikaChefUserToken, ambikaChefUserId]);

    useEffect(() => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/productionReadyProductLis`,
                { UserId: ambikaChefUserId },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setPlaceOrderList(res?.data?.data);
            })
            .catch((err) => {
                // Handle error
            });
    }, [ambikaChefUserToken, ambikaChefUserId]);


    const toggleSection = (index) => {
        setOpenSection(openSection === index ? null : index);
    };

    const handelSendProduction = (orderId, productId) => { 
     setOrderProduction((prevDetails) => ({
        ...prevDetails, 
        orderId: orderId ,
        orderProductId: productId,  
    }));
    setShowSendProduction(true)  
    };

    const handelFoodReady = (orderId, productId) => { 
        setFoodReady((prevDetails) => ({
        ...prevDetails, 
        orderId: orderId ,
        orderProductId: productId,  
    }));
    setShowFoodRedy(true)  
    };

   

    const showAllProduct = () => {
        return placeOrderList?.map((order, index) => (
            <div key={index} className="text-start mt-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className="fw-bold">Order ID : {order.order_id}</p>
                    <p>{order.pickup_date} | {order.pickup_time}</p>
                </div>

                {order.category_detail.map((cat, catIndex) => (
                    <div key={catIndex} className="border rounded-3 border-1 mt-2 bg-light">
                        <div
                            className="p-2 d-flex align-items-center justify-content-between"
                            data-bs-toggle="collapse"
                            href={`#orderCatList${index}-${catIndex}`}
                            role="button"
                            aria-expanded={openSection === `${index}-${catIndex}`}
                            aria-controls={`orderCatList${index}-${catIndex}`}
                            onClick={() => toggleSection(`${index}-${catIndex}`)}
                        >
                            <p className="fs-5 fw-semibold">{cat.category_name}</p>
                            <p>
                                <i className={`bi ${openSection === `${index}-${catIndex}` ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                            </p>
                        </div>
                        <div className={`collapse ${openSection === `${index}-${catIndex}` ? 'show' : ''}`} id={`orderCatList${index}-${catIndex}`}>
                            {cat.product_detail.map((el, productIndex) => (
                                <div key={productIndex} className="border-top p-2">
                                    <div className="mx-2">
                                        <p className="fw-semibold fs-5">{el?.productName}</p>
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                            <p>Weight:</p>
                                            <p>{el?.size_name}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Shape:</p>
                                            <p>{el?.shap_option}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Less Cream:</p>
                                            <p>{el?.eggless_option}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Message on the Cake:</p>
                                            <p>{el?.message_option}</p>
                                        </div>
                                        <div className="d-flex gap-3 align-items-center">
                                            {el?.is_urgent !== 0 && (
                                                <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                                    Urgent
                                                </p>
                                            )}
                                            {el?.is_parcel !== 0 && (
                                                <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                                    Packing
                                                </p>
                                            )}
                                        </div>
                                        <div class='d-flex align-items-center gap-3 my-2'>
                                            <button class='btn btn-success rounded-5 form-control'style={{fontSize:'14px'}} onClick={()=>handelSendProduction( order?.order_id,el?.product_id )}>Send for Production</button>
                                            <button class='btn btn-success rounded-5 form-control'style={{fontSize:'14px'}} onClick={()=>handelFoodReady( order?.order_id,el?.product_id )}>Food Ready</button>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))} 
               
            </div>
        ));
    };
    const handelSendForProduction = ( )=>{
        axios
        .post(
            `${AmbikaChefAPI}/chef/sendForProductionProduct`,
            { UserId: ambikaChefUserId, ...orderProduction,remarks: orderRemark },
            { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
        )
        .then((res) => {
            setShowSendProduction(false) 
            setOrderRemark('') 
            Swal.fire({ 
                text: `${res?.data?.message}`, 
              });
              refreshOrders()
        })
        .catch((err) => {
            // Handle error
        });
    }


    const handelFoodready = ( )=>{
        axios
        .post(
            `${AmbikaChefAPI}/chef/productionReadyProduct`,
            { UserId: ambikaChefUserId, ...foodReady,remarks: orderRemark },
            { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
        )
        .then((res) => {
            setShowFoodRedy(false)  
            setOrderRemark('')  
            Swal.fire({ 
                text: `${res?.data?.message}`, 
              });
              refreshOrders()
        })
        .catch((err) => {
            // Handle error
        });
    }

    return (
        <div>
            {
                showSendProduction === true && 
            <div style={{width:'100%', height:'100%', background:'rgba(0,0,0,0.6)',position:'fixed',top:'0',left:'0',zIndex:'10'}} class='d-flex align-items-center justify-content-center'>
                <div class='p-3 col-md-4 col-10 bg-light rounded-4'>
                    <p class='fs-2'>Remarks for Production</p>
                    <input type="text" placeholder='Write Remarks' class='form-control mt-2' onChange={(e)=>setOrderRemark(e.target.value)} />
                    <div class='d-flex gap-3'>
                    <button class='btn btn-success mt-3 form-control rounded-5' onClick={()=>handelSendForProduction()}> Save</button>
                    <button class='btn  btn-outline-danger mt-3 form-control rounded-5' onClick={()=>  setShowSendProduction(false)}> Close</button>
                    </div>
                </div>

            </div>
            }

{
                showFoodRedy === true && 
            <div style={{width:'100%', height:'100%', background:'rgba(0,0,0,0.6)',position:'fixed',top:'0',left:'0',zIndex:'10'}} class='d-flex align-items-center justify-content-center'>
                <div class='p-3 col-md-4 col-10 bg-light rounded-4'>
                    <p class='fs-2'>Remarks for Food ready</p>
                    <input type="text" placeholder='Write Remarks' class='form-control mt-2' onChange={(e)=>setOrderRemark(e.target.value)} />
                    <div class='d-flex gap-3'>
                    <button class='btn btn-success mt-3 form-control rounded-5' onClick={()=>handelFoodready()}> Save</button>
                    <button class='btn  btn-outline-danger mt-3 form-control rounded-5' onClick={()=>  setShowFoodRedy(false)}> Close</button>
                    </div>
                </div>

            </div>
            }
            <div className="col-lg-6 col-md-8 col-11 m-auto mt-4">
                {
                    placeOrderList !=  '' ?
                    showAllProduct() 
                    : 
                    <div> 
                        <br />
                        <p class='fs-1 mt-4'>No Product Found...</p>
                    </div>
                }
                <br />
            </div>
        </div>
    );
};

export default ProductionProductList;
