import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Order = ({orderList,refreshOrders}) => { 

    const navigate = useNavigate() 
        useEffect(() => {
            if (orderList) {
                const firstNonZeroEntry = Object.entries(orderList).find(([key, value]) => value.totCount > 0);
                if (firstNonZeroEntry) { 
                    const firstKey = firstNonZeroEntry[0]; 
                    console.log(firstKey)
                    if(firstKey == 'orderPlace'){
                        navigate('/order-place')
                    }else if(firstKey == 'acceptedOrder'){
                        navigate('/accepted-order')
                    }else if(firstKey == 'sendForProduction'){
                        navigate('/send-for-production')
                    }else if(firstKey == 'productionReady'){
                        navigate('/production-ready')
                    }else if(firstKey == 'pendingPacking'){
                        navigate('/pending-packing')
                    }else if(firstKey == 'foodReady_report'){
                        navigate('/food-ready-report')
                    }else if(firstKey == 'productionReady_report'){
                        navigate('/production-ready-report')
                    }else if(firstKey == 'packingReady_report'){
                        navigate('/packing-ready-report')
                    }
                }
            }
        }, [orderList]); 

        useEffect(()=>{
            refreshOrders()
        },[])
  return (
    <div>order</div>
  )
}

export default Order