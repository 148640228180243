
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import LandingPage from './camponents/LandingPage';
import Login from './camponents/Login';
import Navbar from './camponents/Navbar';
import { PrivateRoute } from './camponents/PrivateRoute';
import Profile from './camponents/Profile';
import ShowOrder from './camponents/ShowOrder';
import ProductionProductList from './camponents/ProductionProductList';
import OrdrNav from './camponents/OrdrNav';
import OrderList from './camponents/OrderList';
import OrderProcess from './camponents/OrderProcess';
import SendForProduction from './camponents/SendForProduction';
import SendForFoodReady from './camponents/SendForFoodReady';
import PandingPacking from './camponents/PandingPacking';
import FoodReadyReport from './camponents/FoodReadyReport';
import ProductionReadyReport from './camponents/ProductionReadyReport';
import PakingReadyReport from './camponents/PakingReadyReport';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { AmbikaChefAPI } from './camponents/config';
import Order from './camponents/Order';


function App() {

  const [orderList, setOrderList] = useState(null);

  const location = useLocation();
  const isActiveLink = (link) => {
    return link === location.pathname;
  };

  const ambikaChefUserToken = localStorage.getItem('ambikaChefUserToken');
  const ambikaChefUserId = localStorage.getItem('ambikaChefUserId');

  const itemStatu = () => {
    axios
      .post(
        `${AmbikaChefAPI}/chef/chefDashboard`,
        { UserId: ambikaChefUserId },
        { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
      )
      .then((res) => {
        setOrderList(res?.data?.data)
      })
      .catch((err) => { });
  }

  useEffect(() => {
    itemStatu();
  }, []);
  return (
    <div className="App">
      {
        (isActiveLink('/') || isActiveLink('/log-in')) ? '' :
          <>
            <Navbar />
            <div style={{ height: '70px' }}></div>

            <OrdrNav orderList={orderList} refreshOrders={itemStatu} />
          </>
      }
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/log-in' element={<Login />} />
        <Route path='/orders' element={<PrivateRoute><Order  orderList={orderList} refreshOrders={itemStatu}  /></PrivateRoute>} />

        {/* <Route path='/orders' element={<PrivateRoute><ShowOrder /></PrivateRoute>} /> */}
        <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path='/production-product' element={<PrivateRoute><ProductionProductList refreshOrders={itemStatu} /></PrivateRoute>} />
        <Route path='/order-place' element={<PrivateRoute><OrderList refreshOrders={itemStatu} /></PrivateRoute>} />
        <Route path='/accepted-order' element={<PrivateRoute><OrderProcess refreshOrders={itemStatu} /></PrivateRoute>} />
        <Route path='/send-for-production' element={<PrivateRoute><SendForProduction refreshOrders={itemStatu} /></PrivateRoute>} />
        <Route path='/production-ready' element={<PrivateRoute><SendForFoodReady refreshOrders={itemStatu} /></PrivateRoute>} />
        <Route path='/pending-packing' element={<PrivateRoute><PandingPacking refreshOrders={itemStatu} /></PrivateRoute>} />
        <Route path='/food-ready-report' element={<PrivateRoute><FoodReadyReport refreshOrders={itemStatu} /></PrivateRoute>} />
        <Route path='/production-ready-report' element={<PrivateRoute><ProductionReadyReport refreshOrders={itemStatu} /></PrivateRoute>} />
        <Route path='/packing-ready-report' element={<PrivateRoute><PakingReadyReport refreshOrders={itemStatu} /></PrivateRoute>} />





      </Routes>
    </div>
  );
}

export default App;
